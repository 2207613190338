import type { IncomingHttpHeaders } from 'http';

import {
  isAxiosError,
  type AxiosHeaders,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';
import { serialize } from 'cookie';
import type { NextApiRequest, NextApiResponse } from 'next';

import {
  DEFAULT_RESPONSE_ERROR,
  ENDPOINTS,
} from 'shared/infra/account/constants';
import type {
  ApiError,
  CustomerResponse,
  MerchantProfileResponse,
} from 'shared/infra/account/types';
import api from 'shared/infra/api/client';
import { NationalIdType } from 'shared/infra/contentful/types';
import type { Maybe } from 'types/util';
import { getSumupCookieDomain } from 'utils/cookies';

export interface Customer {
  id: string;
  reference: string;
}

interface RequestHeaders extends IncomingHttpHeaders {
  'X-Forwarded-For'?: string | string[];
  'Content-Type'?: string;
  'traceparent'?: string;
}

interface Headers extends AxiosHeaders {
  'Authorization': string;
  'Content-Type': string;
  'X-Forwarded-For'?: string;
  'traceparent'?: string;
}

/**
 * Vercel attaches the user IP Address to the x-forwarded-for header as
 * documented in https://vercel.com/docs/concepts/edge-network/headers#x-forwarded-for.
 * This IP is currently used for rate limiting on ecom-platform and for conversion
 * deduplication during order creation.
 *
 * As botting was interfering with experiments and used up vercel resources,
 * cloudflare was added in front of vercel.
 * Cloudflare mask the IP in the X-Forwarded-For header as vercel will not
 * pass on the original forwarded for header.
 * So we are using the cf-connecting-ip header if it is available. It is added
 * by cloudflare and contains the client ip connecting to cloudflare.
 */
export const getForwardedForHeader = (
  headers: RequestHeaders,
): Maybe<string> => {
  const fw =
    headers['cf-connecting-ip'] ||
    headers['X-Forwarded-For'] ||
    headers['x-forwarded-for'];

  if (Array.isArray(fw)) {
    return fw.join(', ');
  }

  return fw ?? null;
};

export const buildHeaders = (
  authToken: Maybe<string>,
  reqHeaders: RequestHeaders = {},
): Headers => {
  const headers = {
    ...(authToken && { Authorization: authToken }),
    'Content-Type': `${
      reqHeaders['Content-Type'] || 'application/json; charset=utf-8'
    }`,
  } as Headers;

  // We have to pass down the client ip address to properly
  // rate limit requests based on client ip.
  // The x-forwarded-for header is the defactor standard for doing so.
  const fwfHeader = getForwardedForHeader(reqHeaders);
  if (fwfHeader) {
    headers['X-Forwarded-For'] = fwfHeader;
  }

  if (reqHeaders.traceparent) {
    headers.traceparent = reqHeaders.traceparent;
  }

  return headers;
};

export const getAccessToken = (
  req: NextApiRequest,
  res: NextApiResponse,
): string => {
  const authCookie = req.cookies['oidc:token'];

  if (authCookie) {
    // When the shop is opened in the web view, a new cookie is issued
    // if the token has expired. That's why we always set a new cookie
    // on client side.
    res.setHeader(
      'Set-Cookie',
      serialize('oidc:token', authCookie, {
        path: '/',
        domain: getSumupCookieDomain(),
        secure: true,
        httpOnly: true,
        sameSite: 'strict',
      }),
    );

    return `Bearer ${authCookie}`;
  }

  return req.headers.authorization || '';
};

export const buildCustomersPayload = (
  orderId: string,
  email: string,
): {
  data: {
    type: string;
    attributes: { order_id: string; email: string };
  };
} => ({
  data: {
    type: 'customers',
    attributes: {
      order_id: orderId,
      email,
    },
  },
});

export const createCustomer = async (
  orderId: string,
  reqHeaders: RequestHeaders = {},
  authToken: string,
): Promise<CustomerResponse> => {
  const headers = buildHeaders(authToken, {
    'Content-Type': 'application/vnd.api+json',
    ...reqHeaders,
  });
  const response = await api.post<CustomerResponse>(
    ENDPOINTS.CUSTOMERS,
    {
      data: {
        type: 'customers',
        attributes: {
          order_id: orderId,
        },
      },
    },
    {
      otelAttributes: {
        'http.route': '/api/account/createCustomer',
      },
      headers,
    } as AxiosRequestConfig,
  );

  return response.data;
};

export const parseCustomer = (response: CustomerResponse): Customer => ({
  id: response.data.id,
  reference: response.data.attributes.reference,
});

export const getMerchantProfileRequest = (
  authToken: string,
  reqHeaders: NextApiRequest['headers'] = {},
): Promise<AxiosResponse<MerchantProfileResponse>> =>
  api.get<MerchantProfileResponse>(ENDPOINTS.MERCHANT_PROFILE, {
    otelAttributes: {
      'http.route': '/api/account/merchantProfile',
    },
    headers: buildHeaders(authToken, reqHeaders),
  } as AxiosRequestConfig);

export const buildNationalIdUpdatePayload = (
  nationalId: string,
  type: NationalIdType,
): unknown => {
  if (type === NationalIdType.business) {
    return {
      merchant_profile: {
        company_registration_number: nationalId,
      },
    };
  }

  return {
    personal_profile: {
      national_id: nationalId,
    },
  };
};

export const updateUserNationalId = (
  nationalId: string,
  type: NationalIdType,
  authToken: string,
  reqHeaders: NextApiRequest['headers'] = {},
): unknown =>
  api.put(
    ENDPOINTS.REGISTRATION,
    buildNationalIdUpdatePayload(nationalId, type),
    {
      otelAttributes: {
        'http.route': '/api/account/registration',
      },
      headers: buildHeaders(authToken, reqHeaders),
    } as AxiosRequestConfig,
  );

export const getErrorData = (err: unknown): ApiError | string => {
  if (isAxiosError(err)) {
    return (
      (err.response?.data as ApiError) || DEFAULT_RESPONSE_ERROR.MESSAGE_CODE
    );
  }
  return DEFAULT_RESPONSE_ERROR.MESSAGE_CODE;
};

export const getErrorStatusCode = (err: unknown): number => {
  if (isAxiosError(err)) {
    return err.response?.status || DEFAULT_RESPONSE_ERROR.STATUS_CODE;
  }
  return DEFAULT_RESPONSE_ERROR.STATUS_CODE;
};
